<template>
  <el-select
    v-model="propertyModel"
    @change="propertyChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.PROPERTIES')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getProperties"
    :loading="loading"
  >
  <el-option
      v-if="showAll"
      :value="null"
      :label="$t('COMMON.ALL_PROPERTIES')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="property in properties"
      :key="property.id"
      :value="property.id"
      :label="`${property.registration_number}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "property-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    allowCreate: {
      type: Boolean,
      default: false,
      description: "Allow tag creation",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    property: {
      type: String,
      default: null,
      description: "Property id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      propertyModel: this.property,
      properties: {},
    };
  },

  setup() {},

  created() {
    this.getProperties(null, this.property);
  },

  methods: {
    async getProperties(query = null, ids = null) {
      try {
        this.loading = true;
        let params = {
          sort: "-created_at",
          filter: {
            ...(query ? { search: query } : {}),
            ...(ids?.length ? { ids: ids.map((item) => item.id) } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        await this.$store.dispatch("properties/list", params);
        const propertyArr = await this.$store.getters["properties/list"];
        this.properties = {};
        propertyArr.forEach((property) => {
          this.properties[property.id] = property;
        });
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    propertyChanged(property) {
      this.$emit("propertyChanged", property);
    },
  },

  watch: {
    property(property) {
      if (property) {
        this.propertyModel = property;
        if (property !== this.propertyModel) {
          this.getProperties(null, property);
        }
      } else {
        this.propertyModel = null;
      }
    },
    filterOrganization(filterOrganization) {
      this.getProperties();
    },
  },
};
</script>
