export default {
  APP: "APP",
  CUSTOMERS: "CUSTOMERS",
  SUPPLIERS: "SUPPLIERS",
  CONTACTS: "CONTACTS",
  COMMUNICATIONS: "COMMUNICATIONS",
  PRODUCTS: "PRODUCTS",
  FILES: "FILES",
  FOLDERS: "FOLDERS",
  LOCATIONS: "LOCATIONS",
  ORGANIZATIONS: "ORGANIZATIONS",
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
  PACKAGES: "PACKAGES",
  RESELLERS: "RESELLERS",
  USERS: "USERS",
  ROLES: "ROLES",
  PERMISSIONS: "PERMISSIONS",
  LOGS: "LOGS",
  PURCHASES_ORDERS: "PURCHASES_ORDERS",
  PURCHASES_INVOICES: "PURCHASES_INVOICES",
  PURCHASES_DELIVERIES: "PURCHASES_DELIVERIES",
  PURCHASES_PAYMENTS: "PURCHASES_PAYMENTS",
  SALES_ORDERS: "SALES_ORDERS",
  SALES_INVOICES: "SALES_INVOICES",
  SALES_DELIVERIES: "SALES_DELIVERIES",
  SALES_PAYMENTS: "SALES_PAYMENTS",
  RESELLER_INVOICES: "RESELLER_INVOICES",
  RESELLER_PAYMENTS: "RESELLER_PAYMENTS",
  RESELLER_PRODUCTS: "RESELLER_PRODUCTS",
  RESELLER_SERVICES: "RESELLER_SERVICES",
  WAREHOUSES: "WAREHOUSES",
  STOCK_MOVEMENTS: "STOCK_MOVEMENTS",
  IMPORTS: "IMPORTS",
  PROPERTIES: "PROPERTIES",
  AUCTIONS: "AUCTIONS",
  OTHER_FEES: "OTHER_FEES",
  ORGANIZATION_TEMPLATES: "ORGANIZATION_TEMPLATES",
};
