<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    
    <div class="form-wrapper" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)">
        <!-- Organization -->
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
          
        >
          <organization-selector
            :organization="communication.organization.id"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId, organization) => {
                communication.organization.id = organizationId;
                onFormChanged();
              }
            "
            :disabled="!!communication.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>
    
    <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMUNICATIONS.COMMUNICATION_TYPE')} (*)`"
          :placeholder="$t('COMMUNICATIONS.COMMUNICATION_TYPE')"
        >
          <el-select
            :clearable="true"
            class="select-primary pagination-select"
            :placeholder="$t('COMMUNICATIONS.COMMUNICATION_TYPE')"
            v-model="communication.communication_type"
            @change="onFormChanged"
          >
            <el-option
              class="select-primary"
              v-for="(item, key) in COMMUNICATIONS_TYPES"
              :key="key"
              :label="$t(`COMMUNICATIONS.COMMUNICATIONS_TYPE_${item}`)"
              :value="item"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.communication_type" />
      </div>
    
      
      <div class="form-wrapper">
        <!-- Contact -->
        <base-input
          :label="`${$t('COMMUNICATIONS.CONTACT')} (*)`"
          :placeholder="$t('COMMUNICATIONS.CONTACT')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CONTACTS)"
        >
          <contact-selector
            :contact="communication.contact.id"
            :filterable="true"
            :showAll="false"
            :filterOrganization="communication.organization.id"
            @contactChanged="
              (contactId, contact) => {
                communication.contact.id = contactId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.contact" />
      </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.PROPERTIES')}`"
        :placeholder="$t('COMMON.PROPERTIES')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PROPERTIES)"
      >
        <properties-selector
          :properties="communication.properties"
          :filterable="true"
          :showAll="false"
          :multiple="true"
          :filterOwner="communication.contact.id"
          @propertiesChanged="
            (properties) => {
              communication.properties = properties;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.property" />
    </div>

    
    

    <div class="form-wrapper full">
      <base-input
        :label="$t('COMMUNICATIONS.EXCERPT')"
        :placeholder="$t('COMMUNICATIONS.EXCERPT')"
      >
        <html-editor v-model="communication.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMUNICATIONS.TRAKING_NUMBER')}`"
        :placeholder="$t('COMMUNICATIONS.TRAKING_NUMBER')"
        v-model="communication.tracking_number"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.tracking_number" />
    </div>
    <div class="form-wrapper">
      <base-input
          :label="`${$t('COMMUNICATIONS.DATE')}`"
          :placeholder="$t('COMMUNICATIONS.DATE')"
          v-model="communication.date"
          @change="onFormChanged"
        >
          <flat-picker
            :placeholder="$t('COMMUNICATIONS.DATE')"
            :config="{
              allowInput: true,
              enableTime: false,
              type: 'date',
              maxDate: 'today',
              locale: $flatPickrLocale(),
              disable: [
                function (date) {
                  return date.getDay() === 0;
                },
              ],
            }"
            class="form-control datepicker"
            v-model="communication.date"
            @on-change="onFormChanged()"
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.date" />
        </div>
  <div class="form-wrapper full">
    <attachements-selector
      :label="$t('COMMUNICATIONS.ATTACHEMENTS')"
      :defaultAttachements="communication.attachements"
      ressource_name="communications"
      :ressource_id="communication.id ? communication.id : 0"
      field="attachements"
      @attachementsChanged="
        (attachements_urls) => {
          communication.attachements = attachements_urls;
          onFormChanged();
        }
      "
    />
  </div>
    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          communication.id ? $t("COMMUNICATIONS.EDIT_COMMUNICATION") : $t("COMMUNICATIONS.ADD_COMMUNICATION")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
  import Vue from 'vue';
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import AttachementsSelector from "@/components/AttachementsSelector.vue";
import TaxesSelector from "@/components/TaxesSelector.vue";
import PropertiesSelector from "@/components/PropertiesSelector.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  COMMUNICATIONS_TYPES
} from "@/constants/communications";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ContactSelector from "@/components/ContactSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    AttachementsSelector,
    PropertiesSelector,
    OrganizationSelector,
    ContactSelector,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["communicationData", "formErrors", "loading"],

  data() {
    let communicationData = cloneDeep(this.communicationData);
    communicationData = this.$fillUserOrganizationData(communicationData);
    return {
      communication: communicationData,
      COMMUNICATIONS_TYPES,
      permissions: [],
      contacts: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {},

  mounted() {
    this.getContacts();
  },

  methods: {

    async getContacts() {
      await this.$store.dispatch("contacts/list");
      this.contacts = this.$store.getters["contacts/list"];
    },
    async handleSubmit() {
      let communicationData = cloneDeep(this.communication);
      communicationData = this.$fillUserOrganizationData(communicationData);
      this.$emit("communicationSubmitted", communicationData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    communicationData(communicationData) {
      if (communicationData) {
        this.communication = {
          ...this.communication,
          ...cloneDeep(communicationData),
        };
      }
    },
  },
};
</script>
