<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("COMMUNICATIONS.COMMUNICATIONS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openCommunicationCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_COMMUNICATIONS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("COMMUNICATIONS.ADD_COMMUNICATION")
              }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_COMMUNICATIONS)"
              :objectType="'communications'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <communication-list-table
        @onViewCommunication="openCommunicationViewModal"
        @onEditCommunication="openCommunicationEditModal"
        @onDeleteCommunication="deleteCommunication"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewCommunicationModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewCommunicationModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'COMMUNICATION_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("COMMUNICATIONS.VIEW_COMMUNICATION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openCommunication"
                :objectType="'communications'"
                :objectId="openCommunication.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openCommunicationEditModal(openCommunication)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="edit" @click="deleteCommunication(openCommunication)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeCommunicationModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-communication-page v-if="openCommunication" :communicationId="openCommunication.id" />
          </div>
        </div>
      </div>

      <div
        v-if="isEditCommunicationModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditCommunicationModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'COMMUNICATION'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("COMMUNICATIONS.EDIT_COMMUNICATION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openCommunicationViewModal(openCommunication)">
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeCommunicationModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-communication-page
              v-if="openCommunication"
              :communicationId="openCommunication.id"
              @onViewCommunication="openCommunicationViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddCommunicationModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddCommunicationModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'COMMUNICATION'">
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("COMMUNICATIONS.ADD_COMMUNICATION") }}
            </h1>
            <button class="close" @click="closeCommunicationModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-communication-page @onViewCommunication="openCommunicationViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import CommunicationListTable from "./partials/CommunicationListTable.vue";
import EditCommunicationPage from "./components/EditCommunicationComponent.vue";
import AddCommunicationPage from "./components/AddCommunicationComponent.vue";
import ViewCommunicationPage from "./components/ViewCommunicationComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    CommunicationListTable,
    NotificationSubscription,
    EditCommunicationPage,
    AddCommunicationPage,
    ViewCommunicationPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const communicationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewCommunicationModalOpened = false;
    let isEditCommunicationModalOpened = false;
    let isAddCommunicationModalOpened = false;
    let openCommunication = null;
    if (communicationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewCommunicationModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditCommunicationModalOpened = true;
      }
      openCommunication = { id: communicationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddCommunicationModalOpened = true;
    }
    return {
      isViewCommunicationModalOpened: isViewCommunicationModalOpened,
      isEditCommunicationModalOpened: isEditCommunicationModalOpened,
      isAddCommunicationModalOpened: isAddCommunicationModalOpened,
      openCommunication: openCommunication,
      renderKey: 1,
    };
  },

  methods: {
    openCommunicationCreateModal() {
      this.closeCommunicationModal();
      this.isAddCommunicationModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Communications",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openCommunicationViewModal(communication, reRender = false) {
      this.closeCommunicationModal();
      this.openCommunication = communication;
      this.isViewCommunicationModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Communications",
          query: { id: this.openCommunication.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openCommunicationEditModal(communication) {
      this.closeCommunicationModal();
      this.openCommunication = communication;
      this.isEditCommunicationModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Communications",
          query: { id: this.openCommunication.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeCommunicationModal() {
      this.isAddCommunicationModalOpened = false;
      this.isViewCommunicationModalOpened = false;
      this.isEditCommunicationModalOpened = false;
      this.openCommunication = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Communications",
          query: {},
        }).href
      );
    },

    async deleteCommunication(communication) {
      const confirmation = await swal.fire({
        title: this.$t("COMMUNICATIONS.DELETE_THIS_COMMUNICATION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("communications/destroy", communication.id);
          this.renderKey++;
          this.closeCommunicationModal();
          this.$notify({
            type: "success",
            message: this.$t("COMMUNICATIONS.COMMUNICATION_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
